<template>
  <div id="box" class="body">
    <div class="banxin">
      <Breadcrumb />
      <main class="user">
        <aside v-if="aside" class="user-menu">
          <div class="h1">个人中心</div>
          <div v-for="(item, index) in userInfoList" :key="index">
            <div style="color: #333" class="title fs16">
              <img width="15" :src="item.headerImg" alt />
              <div>{{ item.head }}</div>
            </div>
            <ul>
              <li
                style="color: #707070; font-size: 14px"
                @click="clkActive(it)"
                :class="{ active: asideActive == it.name }"
                v-for="(it, id) in item.body"
                v-show="it.name"
                :key="id"
              >
                <span> {{ it.name }}</span>
              </li>
            </ul>
          </div>
        </aside>
        <article class="user-content article">
          <!-- 应该用来放动态变化的路由对应的组件 -->
          <router-view></router-view>
        </article>
      </main>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
export default {
  data() {
    return {
      aside: true,
      asideActive: "",
      userInfoList: [
        // {
        //   head: '首页',
        //   headerImg: require('../../assets/images/users/t1.png'),
        //   body: [
        //     {
        //       name: '账户信息',
        //       path: "/user/userinfo"
        //     }
        //   ]
        // },
        {
          head: "订单中心",
          headerImg: require("../../assets/images/users/t2.png"),
          body: [
            {
              name: "我的订单",
              path: "/user/myOrder",
            },
          ],
        },
        {
          head: "发票中心",
          headerImg: require("../../assets/images/users/t10.png"),
          body: [
            {
              name: "我的发票",
              path: "/user/myInvoice",
            },
            /*  {
              name: "申请发票",
              path: "/user/applyInvoice",
            }, */
          ],
        },
        // {
        //   head: '我的商品',
        //   headerImg: require('../../assets/images/users/t3.png'),
        //   body: [
        //     {
        //       name: "我的收藏",
        //       path: "/user/myFavorites"
        //     },
        //     {
        //       name: "浏览记录",
        //       path: ""
        //     },

        //   ]
        // },
        // {
        //   head: '消息中心',
        //   headerImg: require('../../assets/images/users/t4.png'),
        //   body: [
        //     {
        //       name: "我的消息",
        //       path: "/user/myMessage"
        //     },
        //   ]
        // },
        // {
        //   head: '福利中心',
        //   headerImg: require('../../assets/images/users/t5.png'),
        //   body: [
        //     {
        //       name: "我的优惠",
        //       path: ""
        //     },
        //   ]

        // },
        {
          head: "资产中心",
          headerImg: require("../../assets/images/users/t6.png"),
          body: [
            localStorage.getItem("isloan")
              ? {
                  name: "我的药采贷",
                  path: "/user/myLoan",
                }
              : {},
            {
              name: "我的慧金豆",
              path: "/user/preferential",
            },
            {
              name: "我的积分",
              path: "/user/myPoints",
            },
            // {
            //   name: "开票记录",
            //   path: ""
            // },
            // {
            //   name: "发票抬头管理",
            //   path: ""
            // },
          ],
        },
        {
          head: "报表管理",
          headerImg: require("../../assets/images/users/t9.png"),
          body: [
            {
              name: "订单报表",
              path: "/user/OrderReport",
            },
            /* {
              name: "账单报表",
              path: "/user/billReport",
            }, */
          ],
        },
        {
          head: "售后中心",
          headerImg: require("../../assets/images/users/t7.png"),
          body: [
            {
              name: "售后管理",
              path: "/user/Aftersales",
            },
          ],
        },
        /* {
          head: "控销采购",
          headerImg: require("../../assets/images/users/t7.png"),
          body: [
            {
              name: "我的采购计划",
              path: "/user/ProcurementPlanning",
            },
          ],
        }, */
        {
          head: "账期报表",
          headerImg: require("../../assets/images/users/t8.png"),
          body: [
            {
              name: "账期使用记录",
              path: "/user/accountPeriod",
            },
          ],
        },
        {
          head: "系统设置",
          headerImg: require("../../assets/images/users/t8.png"),
          body: [
            // {
            //   name: "账户安全",
            //   path: "",
            // },
            {
              name: "重置支付密码",
              path: "/user/resetPassword",
            },
            // {
            //   name: "子账号管理",
            //   path: "",
            // },
          ],
        },
      ],
    };
  },
  created() {},
  components: {
    Breadcrumb,
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal, olVal) {
        this.userInfoList.map((item) => {
          item.body.map((it) => {
            if (it.path == newVal) {
              this.asideActive = it.name;
            }
          });
        });

        if (
          newVal.includes("/User/MyOrder") ||
          newVal.includes("/user/myLoan/myLoanDetail") ||
          newVal.includes("/user/myLoan/myLoanOrderList") ||
          newVal.includes("/user/myOrder/orderDetail") ||
          newVal.includes("/user/ProcurementPlanning/ProcurementDetail") ||
          newVal.includes("/User/MyOrder/aftersele") ||
          newVal.includes("/user/myOrder/afterseleDetail") || 
          newVal.includes("/user/applyInvoice/ApplyInvoiceConfirm") || 
          newVal.includes("/user/myInvoice/detail")
        ) {
          this.aside = false;
        } else {
          this.aside = true;
        }
      },
    },
  },
  methods: {
    clkActive(it) {
      this.asideActive = it.name;
      if (it.path != "") {
        this.$router.push(it.path);
      }
    },
    // 退出登录
    userOut() {
      localStorage.removeItem("x-auth-token");
      this.$store.dispatch("tosetAsync", {
        icon: "success",
        content: "退出登录成功,即将跳入首页",
      });
      this.$emit("keyGai");
      setTimeout(() => {
        this.$router.push("/home");
      }, 1000);
    },
    // 解绑微信
    userJb() {},
    // dl(){
    //   localStorage.setItem('x-auth-token','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzU4MTgyNTEsImlhdCI6MTYzNTIxMzQ1MSwidXNlcm5hbWUiOiJlNjM3OWIxNC1kOGVmLTRjOTEifQ.EjDF0VjG1NIRSUGabSpsaXExPyHy0lMUFLpe9GkUlq0')
    // }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/base.less";
.body {
  background-color: #f5f5f5 !important;
}
.h1 {
  color: #333333;
  margin-bottom: 28px;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
}
.user-menu {
  height: auto;
}
.user {
  display: flex;
  justify-content: space-between;
  min-height: 740px;
  &-menu {
    flex: 0 0 200px;
    box-sizing: border-box;
    padding: 32px 24px;
    margin-right: 30px;
    background: #fff;
    .avatar {
      width: 100px;
      height: 100px;
      display: block;
      margin: 30px auto 19px;
    }
    .userInfo {
      text-align: center;
      margin-bottom: 43px;
      a {
        color: @blue;
      }
    }
    .title {
      margin-top: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      img {
        margin-right: 20px;
      }
    }
    ul {
      li {
        // margin-bottom: 17px;
        color: #666666;
        cursor: pointer;
        height: 36px;
        padding: 0 0 0 40px;
        line-height: 36px;
        &.active {
          color: #26c487 !important;
          background: #eefbf6;
        }
      }
    }
  }
  &-content {
    flex-grow: 1;
    /* margin-left: 30px; */
    background-color: #fff;
  }
}
</style>
